.contact-link {
	@apply text-h5 my-2;
}

.link-hover {
	@apply cursor-pointer relative overflow-hidden pb-[5px];

	&:hover {
		&:after {
			transform: translateX(100%);
		}
	}

	&:after {
		transition: all 150ms ease-in-out;
		content: "";
		position: absolute;
		background-color: #ffffff;
		bottom: 0px;
		height: 3px;
		width: 100%;
		left: -100%;
		z-index: 3;
	}
}
