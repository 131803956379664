@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fade-in 150ms ease-in-out;
}
