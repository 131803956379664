@import "../../node_modules/swiper/swiper.scss"; // core Swiper
@import "../../node_modules/swiper/modules/navigation/navigation.scss"; // Navigation module
@import "../../node_modules/swiper/modules/pagination/pagination.scss"; // Pagination module

.swiper-wrapper {
	@apply pb-12 lg:pb-[80px];
}

.swiper-slide {
	@apply flex flex-col lg:flex-row-reverse h-full px-col2 lg:px-col1;
}

.swiper-pagination {
	@apply ml-col2 lg:ml-col1;
	text-align: left !important;
}

.swiper-pagination-bullet-active {
	background: #ffffff !important;
}

.swiper-pagination-bullet {
	background: transparent;
	border: 1px solid #ffffff !important;
	opacity: 1 !important;
	width: 10px;
	height: 10px;
	margin: 0 20px 0 0 !important;
}
